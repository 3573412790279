import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithCredential,
} from "firebase/auth";
import firebaseAuth from "app/utils/firebase";
import { useDispatch, batch } from "react-redux";
import { getUser, userSignup } from "app/redux/userSlice";
import { useHistoryTracker } from "./hooks/usehistoryTracker";
import { useQuery } from "app/utils/useQuery";
import http from "app/requests/axiosInstance";
import { toast } from "react-toastify";
import { Spinner } from "@nextui-org/react";
import { Loading } from "app/components/loading/loading";

export interface UserContextType {
  emailPassSignUp: (email: string, password: string) => Promise<any>;
  user: any;
  logout: () => Promise<any>;
  emailPassSignIn: (email: string, password: string) => Promise<any>;
  googlePopupSignIn: () => Promise<any>;
  googleRedirectSignIn: () => Promise<any>;
  signInWithCustomToken: (token: string) => Promise<any>;
  handleGoogleRedirect: () => Promise<any>;
  handleGooglePopupSignin: () => Promise<any>;
  history: any[];
}

export const UserContext: any = createContext({});

export const AuthContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const history = useHistoryTracker(); // Use the custom history tracking hook
  const query = useQuery();

  const googlePopupSignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    return signInWithPopup(firebaseAuth, provider);
  };

  const googleRedirectSignIn = ({ redirectUri }: { redirectUri: string }) => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const responseType = "code";
    const scope = "email profile";
    const state = "google";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&prompt=select_account&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${encodeURIComponent(
      state
    )}`;
    return (window.location.href = authUrl);
  };

  const emailPassSignUp = (email: string, password: string) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
  };

  const emailPassSignIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
  };

  const signInWithCustomToken = (token: string) => {
    return firebaseSignInWithCustomToken(firebaseAuth, token);
  };

  const logout = async () => {
    setUser(null);
    return signOut(firebaseAuth);
  };

  const completeGoogleSignIn = async (googleUser: any) => {
    const nameArray = googleUser.displayName.split(" ");
    try {
      const user = await dispatch(
        userSignup({
          email: googleUser.email,
          firstName: nameArray[0],
          lastName: nameArray[1],
          // uid: googleUser.uid,
        }) as any
      ).unwrap();
      setUser(user);
    } catch (error: any) {
      toast.error(error.code || error.message);
    }
  };

  const handleGooglePopupSignin = async () => {
    try {
      const { user } = await googlePopupSignIn();
      await completeGoogleSignIn(user);
    } catch (error: any) {
      toast.error(error.code || error.message);
    }
  };

  const handleGoogleRedirect = async () => {
    const { data } = await http.post("https://oauth2.googleapis.com/token", {
      code: query.get("code"),
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: `${window.location.origin}${window.location.pathname}`,
      grant_type: "authorization_code",
    });

    const googleCredential = GoogleAuthProvider.credential(data.idToken); // idToken received from the server
    const { user } = await signInWithCredential(firebaseAuth, googleCredential);
    await completeGoogleSignIn(user);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
      (async () => {
        if (currentUser) {
          const userWithInfo = await dispatch(getUser() as any).unwrap();
          // await batch(async () => {
          //   // Dispatch all thunks and gather their promises
          //   const conversationPromise = dispatch(
          //     getConversations() as any
          //   ).unwrap();
          //   const dailyReadingPromise = dispatch(
          //     getDailyReading({ type: "horoscope" }) as any
          //   ).unwrap();
          //   const tarotReadingsPromise = dispatch(
          //     getTarotReadings() as any
          //   ).unwrap();

          //   // Wait for all promises to resolve
          //   await Promise.all([
          //     conversationPromise,
          //     dailyReadingPromise,
          //     tarotReadingsPromise,
          //   ]);
          // });
          setUser(userWithInfo);
        }
        setLoading(false);
      })();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="absolute w-full h-[100dvh] overflow-hidden">
        <Loading fullScreen showBackdrop />;
      </div>
    );
  }

  return (
    <UserContext.Provider
      value={{
        emailPassSignUp,
        user,
        logout,
        emailPassSignIn,
        googlePopupSignIn,
        googleRedirectSignIn,
        signInWithCustomToken,
        handleGoogleRedirect,
        handleGooglePopupSignin,
        history,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
