import { AsButton, AsInput, PaymentModalHeader } from "app/subframe";
import { motion } from "framer-motion";
import * as React from "react";
import { useCreditCardPaymentModal } from "./useCreditCardPaymentModal";
import { Loading } from "app/components/loading/loading";
import classNames from "classnames";

export interface CreditCardPaymentModalProps {
  setIsCreditCardPaymentModalOpen: (open: boolean) => void;
  isProcessingCreditCardCharge: boolean;
  onPayWithCreditCard: (ref: any) => void;
}

export const CreditCardPaymentModal: React.FC<CreditCardPaymentModalProps> = ({
  setIsCreditCardPaymentModalOpen,
  isProcessingCreditCardCharge,
  onPayWithCreditCard,
}) => {
  const { cardFormRef } = useCreditCardPaymentModal();

  return (
    <>
      {isProcessingCreditCardCharge && (
        <div className="absolute left-1/2 self-center top-1/3 -translate-x-1/2">
          <Loading label="Processing payment" labelClassName="text-white" />
        </div>
      )}
      <motion.div
        initial={{ y: 200, opacity: 0 }} // Start off-screen if animating
        animate={{ y: 0, opacity: 1 }} // Slide up and fade in if animating
        transition={{ duration: 0.2, ease: "easeInOut" }} // Animation settings
        className={classNames(
          "flex w-112 absolute bottom-0 left-0 rounded-t-lg flex-col items-center gap-6 bg-white px-2 py-4 shadow-pop-up-shadow  mobile:w-full",
          {
            invisible: isProcessingCreditCardCharge,
          }
        )}
      >
        <PaymentModalHeader
          text="Pay on app"
          buttonProps={{
            onClick: () => setIsCreditCardPaymentModalOpen(false),
          }}
        />
        <div className="flex w-full flex-col items-center gap-6">
          <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
            <span className="w-full text-body-bold font-body-bold text-neutral-900">
              CC Info
            </span>
            <div className="flex flex-col gap-2 w-full h-[110px]">
              <div id="accept-blue-iframe" />
            </div>
          </div>
        </div>
        <AsButton
          className="h-14 w-full flex-none"
          variant="default"
          leadingIcon={null}
          trailingIcon={null}
          text="Pay now"
          size="lg"
          loader={isProcessingCreditCardCharge}
          onClick={() => onPayWithCreditCard(cardFormRef)}
        />
      </motion.div>
    </>
  );
};
