import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Path } from "app/path";
import { ProtectedRoute } from "./protectedRoutes";
import { NotFound } from "app/notFound/notFound";
import { AuthContextProvider } from "app/context/AuthContext";
import { Home } from "app/home/home";
import { Book } from "app/book/book";
import { BookService } from "app/book/bookService/bookService";
import { BookTime } from "app/book/bookTime/bookTime";
import { BookStaff } from "app/book/bookStaff/bookStaff";
import { Order } from "app/order/order";
import { OrderComplete } from "app/order/orderComplete/orderComplete";
// import { Home } from "app/home/home";
// import { Terms } from "app/legal/terms";
// import { Policy } from "app/legal/policy";

export const Router: React.FC = () => {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path={Path.HOME} element={<Home />} />
        <Route path={Path.EXPLORE} element={<Home />} />
        <Route path={Path.BOOK} element={<Book />} />
        <Route path={Path.BOOK_STAFF} element={<BookStaff />} />
        <Route path={Path.BOOK_SERVICE} element={<BookService />} />
        <Route path={Path.BOOK_TIME} element={<BookTime />} />
        <Route path={Path.ORDER} element={<Order />} />
        <Route path={Path.ORDER_COMPLETE} element={<OrderComplete />} />
        {/* <Route path={Path.SIGNUP} element={<Signup />} /> */}
        {/* <Route path={Path.SIGNIN} element={<Signin />} /> */}
        {/* <ProtectedRoute path={Path.SETTINGS} element={<Settings />} /> */}
        {/* <Route path={Path.TERMS} element={<Terms />} /> */}
        {/* <Route path={Path.POLICY} element={<Policy />} /> */}
      </Routes>
    </AuthContextProvider>
  );
};
