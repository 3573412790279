// BookTime.tsx

import * as React from "react";
import { useBookTime } from "./useBookTime";
import {
  BreadcrumbChip,
  CtaPopUp,
  Header,
  IconButton,
  PoweredBy,
} from "app/subframe";
import { DateChip } from "app/subframe/components/DateChip";
import { TimeChip } from "app/subframe/components/TimeChip";
import { Icon } from "@subframe/core";
import { Loading } from "app/components/loading/loading";
import { format, isBefore, startOfDay } from "date-fns";
import { motion } from "framer-motion";
import classNames from "classnames";
import { BookTimeModal } from "./components/modal/bookTimeModal";
import { convertTo12HourFormat } from "app/utils/formatDate";
import { GenericSkeleton } from "app/components/skeleton/genericSkeleton";

export interface BookTimeProps {}

export const BookTime: React.FC<BookTimeProps> = (props) => {
  const {
    updateCartWithTime,
    isLoading,
    selectedDate,
    setSelectedDate,
    selectedTimeSlot,
    setSelectedTimeSlot,
    currentShopTime,
    timezone,
    cart,
    handlePreviousWeek,
    handleNextWeek,
    daysOfWeek,
    availableTimeSlots,
    currentWeekStart,
    datesAvailability,
    navigateToService,
    navigateToStaff,
    account,
  } = useBookTime();

  if (!cart.shop) {
    return (
      <div className="pt-2 pb-4">
        <GenericSkeleton />
      </div>
    );
  }

  return (
    <section className="flex flex-col items-center h-[95vh]">
      <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
        <Header
          className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
          image={account.logo}
          text={account.name}
        />
        <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
          <div className="flex w-full items-center justify-between">
            <BreadcrumbChip
              text="Service"
              onClick={() => navigateToService()}
            />
            <Icon
              className="text-body font-body text-default-font"
              name="FeatherChevronRight"
            />
            <BreadcrumbChip text="Staff" onClick={() => navigateToStaff()} />
            <Icon
              className="text-body font-body text-default-font"
              name="FeatherChevronRight"
            />
            <BreadcrumbChip text="Time" selected={true} />
          </div>

          {/* Date Navigation and Selection */}
          <div className="flex w-full flex-col items-center justify-center gap-6">
            <div className="flex w-full items-center justify-between">
              <IconButton
                disabled={false}
                variant="neutral-secondary"
                size="small"
                icon="FeatherChevronLeft"
                loading={false}
                onClick={handlePreviousWeek}
              />
              <span className="text-[18px] font-[500] leading-[28px] text-neutral-900">
                {currentWeekStart.toLocaleDateString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <IconButton
                disabled={false}
                variant="neutral-secondary"
                size="small"
                icon="FeatherChevronRight"
                loading={false}
                onClick={handleNextWeek}
              />
            </div>
            <div className="flex w-full items-center justify-between">
              {daysOfWeek.map((date) => {
                const dateKey = date.toISOString().split("T")[0]; // Updated here
                const isPastDate = isBefore(
                  startOfDay(date),
                  startOfDay(currentShopTime)
                );
                const hasAvailability = datesAvailability[dateKey];
                const isDisabled = isPastDate || !hasAvailability;
                const isSelected =
                  selectedDate &&
                  selectedDate.toISOString().split("T")[0] === dateKey; // Updated here
                return (
                  <DateChip
                    key={dateKey}
                    days={date.toLocaleDateString("en-US", {
                      weekday: "short",
                    })}
                    date={date.getDate().toString()}
                    disabled={isDisabled}
                    selected={isSelected}
                    onClick={() => !isDisabled && setSelectedDate(date)}
                  />
                );
              })}
            </div>
          </div>

          {/* Time Selection */}
          <div className="flex w-full flex-col items-center gap-6 pt-4 flex-grow">
            {selectedDate && (
              <div className="flex w-full items-center justify-between">
                <p className="w-full text-[18px] font-[500] leading-[28px] text-neutral-900">
                  {format(selectedDate, "eee, MMM d")}
                </p>
                <p className="text-xs w-full text-end">
                  {timezone.timeZoneName}
                </p>
              </div>
            )}
            {isLoading ? (
              <span className="text-body text-neutral-700">
                Loading time slots...
              </span>
            ) : availableTimeSlots.length > 0 ? (
              <div
                className={classNames(
                  "grid w-full grid-cols-2 items-center gap-4 overflow-y-auto flex-grow",
                  {
                    "pb-32": selectedTimeSlot, // Add bottom padding when modal is visible
                    "pb-10": !selectedTimeSlot, // Add bottom padding when modal is visible
                  }
                )}
              >
                {availableTimeSlots.map((slot: string) => (
                  <TimeChip
                    key={slot}
                    className="h-auto grow shrink-0 basis-0"
                    time={convertTo12HourFormat(slot)}
                    selected={selectedTimeSlot === slot}
                    onClick={() => setSelectedTimeSlot(slot)}
                  />
                ))}
              </div>
            ) : selectedDate ? (
              <span className="text-body text-neutral-700">
                No available time slots
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {selectedTimeSlot && (
        <motion.div
          initial={{ y: 200, opacity: 0 }} // Start off-screen if animating
          animate={{ y: 0, opacity: 1 }} // Slide up and fade in if animating
          transition={{ duration: 0.5, ease: "easeInOut" }} // Animation settings
          className={classNames(
            "fixed bottom-0 z-20 bg-transparent backdrop-blur-sm  w-112 flex-none mobile:w-full mobile:flex-none flex flex-col items-center shadow-pop-up-shadow gap-2 rounded-t-lg px-2 py-4"
          )}
        >
          <div className="flex flex-col gap-2 w-full">
            <BookTimeModal updateCartWithTime={updateCartWithTime} />
            <PoweredBy
              className={classNames({})}
              image="https://res.cloudinary.com/subframe/image/upload/v1726585175/uploads/3988/uugigbcbw0ej0hiy77rr.svg"
              text="Powered by"
            />
          </div>
        </motion.div>
      )}
    </section>
  );
};
