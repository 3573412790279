import { Icon } from "@subframe/core";
import { Header, LineItems, RequestedPayment } from "app/subframe";
import * as React from "react";
import { useOrderComplete } from "./useOrderComplete";
import { getFormatedDuration } from "app/book/bookService/useBookService";
import { format } from "date-fns";
import { Loading } from "app/components/loading/loading";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { PaymentMethods } from "app/order/useOrder";
import { Avatar } from "app/components/avatar/avatar";

export interface OrderCompleteProps {}

export const OrderComplete: React.FC<OrderCompleteProps> = ({}) => {
  const { account, cart, appointmentStartTimes, paymentMethod } =
    useOrderComplete();

  if (!account.id || !cart.id) return <Loading fullScreen showBackdrop />;

  const getPaymentMethodElement = () => {
    switch (paymentMethod) {
      case PaymentMethods.CREDIT_CARD:
        return (
          <div className="flex w-full items-center gap-4">
            <div className="flex items-center justify-center gap-2 rounded-md border border-solid border-neutral-50 bg-white px-2 py-1">
              <Icon
                className="text-body font-body text-neutral-900"
                name="FeatherCreditCard"
              />
            </div>
            <span className="text-body font-body text-default-font">
              Paid with Credit Card
            </span>
          </div>
        );
      case PaymentMethods.PAY_IN_PERSON:
      case PaymentMethods.PRE_AUTH:
        return (
          <div className="flex w-full items-center gap-4">
            <div className="flex items-center justify-center gap-2 rounded-md border border-solid border-neutral-50 bg-white px-2 py-1">
              <Icon
                className="text-body font-body text-neutral-900"
                name="FeatherStore"
              />
            </div>
            <span className="text-body font-body text-default-font">
              Pay in Person
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  const paidWithElement = getPaymentMethodElement();

  return (
    <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account.logo}
        text={account.name}
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <RequestedPayment text="You are all set!" single={true} />
        <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
          {cart.merchantServices.map((service, index) => {
            const day = format(
              preserveUtcTimeToLocal(cart.startTime!.split("T")[0]),
              "MMM d"
            );
            return (
              <>
                <div className="flex w-full items-center gap-4">
                  <Avatar
                    src={service.employeeAvatar}
                    size={54}
                    name={service.employee.name}
                  />
                  <div className="flex flex-col items-start justify-center gap-2">
                    <span className="text-body-bold font-body-bold text-neutral-700">
                      {service.employee.name}
                    </span>
                  </div>
                </div>
                <LineItems title="Service" value={service.title} />
                <LineItems
                  title="Duration"
                  value={getFormatedDuration(service)}
                />
                <LineItems
                  title="Time"
                  value={`${day} at ${appointmentStartTimes[index]}`}
                  additionalInfo=""
                />
                {paidWithElement && (
                  <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
                )}
              </>
            );
          })}
          {paidWithElement}
        </div>
        <div className="flex w-full flex-col items-center gap-2">
          <span className="w-full text-[18px] font-[500] leading-[28px] text-neutral-900">
            {cart.shop.name}
          </span>
          <div className="flex w-full flex-col items-center gap-1">
            <span className="w-full text-[14px] font-[400] leading-[20px] text-neutral-500">
              {cart.shop.addressLine1}
            </span>
            <span className="w-full text-[14px] font-[400] leading-[20px] text-neutral-500">
              {cart.shop.addressLine2}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
