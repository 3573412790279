export enum Path {
  HOME = "/",
  EXPLORE = "/book",
  BOOK = "/book/:nameKey",
  BOOK_SERVICE = "/book/:nameKey/:shopKey/service/",
  BOOK_STAFF = "/book/:nameKey/:shopKey/service/:serviceId/serviceProvider",
  BOOK_TIME = "/book/:nameKey/:shopKey/schedule",
  ORDER = "/order/:nameKey/:shopKey",
  ORDER_COMPLETE = "/order/:nameKey/:shopKey/complete",
  SIGNUP = "/signup",
  SIGNIN = "/signin",
  SETTINGS = "/settings",
}
