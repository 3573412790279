import * as React from "react";
import { getFormatedDuration, useBookService } from "./useBookService";
import {
  BreadcrumbChip,
  Header,
  CategoryAccordion,
  ServiceCard,
} from "app/subframe";

import { Icon } from "@subframe/core";
import { getSubTotal } from "app/book/utils/getSubTotal";
import { Loading } from "app/components/loading/loading";
import classNames from "classnames";
import { ExistingServices } from "./components/existingServices";
import { GenericSkeleton } from "app/components/skeleton/genericSkeleton";

export interface BookServiceProps {}

export const BookService: React.FC<BookServiceProps> = ({}) => {
  const {
    shopProvidedServicesCategories,
    handleServiceClick,
    cart,
    isLoading,
    onRemoveMerchantService,
    navigateToStaff,
    navigateToTime,
    account,
  } = useBookService();

  if (isLoading) {
    return (
      <div className="pt-2 pb-4">
        <GenericSkeleton />
      </div>
    );
  }

  return (
    <section className="">
      <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
        <Header
          className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
          image={account.logo}
          text={account.name}
        />
        <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
          <div className="flex w-full items-center justify-between">
            <BreadcrumbChip text="Service" selected={true} />
            <Icon
              className="text-body font-body text-default-font"
              name="FeatherChevronRight"
            />
            <BreadcrumbChip
              text="Staff"
              onClick={() => navigateToStaff()}
              disabled={
                !cart.merchantServices || cart.merchantServices.length === 0
              }
            />
            <Icon
              className="text-body font-body text-default-font"
              name="FeatherChevronRight"
            />
            <BreadcrumbChip
              text="Time"
              onClick={() => navigateToTime()}
              disabled={
                !cart.merchantServices || cart.merchantServices.length === 0
              }
            />
          </div>
          {cart?.merchantServices?.length > 0 && (
            <ExistingServices
              cart={cart}
              onRemoveMerchantService={onRemoveMerchantService}
              navigateToTime={navigateToTime}
            />
          )}
          <div className="flex w-full flex-col items-center gap-2">
            {shopProvidedServicesCategories.map((category: any) => (
              <CategoryAccordion text={category.name} key={category.id}>
                {category.services.map((service: any, index: number) => (
                  <ServiceCard
                    key={service.id}
                    text={service.title}
                    text2={getFormatedDuration(service)}
                    text3={service.price}
                    className={classNames("border-x-1 border-b-1", {
                      "border-t-1": index === 0,
                      "border-t-0": index !== 0,
                      "opacity-50 !border-none hover:!bg-transparent cursor-default":
                        service.selected,
                    })}
                    onClick={() =>
                      !service.selected && handleServiceClick(service)
                    }
                  />
                ))}
              </CategoryAccordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
