import { SkeletonCircle } from "app/subframe/components/SkeletonCircle";
import { SkeletonText } from "app/subframe/components/SkeletonText";
import * as React from "react";

export interface GenericSkeletonProps {}

export const GenericSkeleton: React.FC<GenericSkeletonProps> = (props) => {
  const {} = props;
  return (
    <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 !pt-4 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-6 px-2 pt-10 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
      </div>
    </div>
  );
};
