import * as React from "react";
import classnames from "classnames";
import LoaderSvg from "app/assets/icons/loader.svg";

export interface LoadingProps {
  fullScreen?: boolean;
  label?: string;
  labelClassName?: string;
  showBackdrop?: boolean;
  containerClassName?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  fullScreen = false,
  label,
  labelClassName,
  showBackdrop = false,
  containerClassName,
}) => {
  return (
    <div
      className={classnames(
        `flex flex-col relative items-center justify-center ${containerClassName}`,
        {
          "h-screen": fullScreen,
          "h-full": !fullScreen,
          "bg-[#171718]/80": showBackdrop,
        }
      )}
    >
      <img
        src={LoaderSvg}
        alt="loading"
        className="w-12 h-12 animate-spin-slow"
      />
      <p
        className={classnames(labelClassName, {
          "text-white": showBackdrop,
          "text-neutral-900": !showBackdrop,
        })}
      >
        {label}
      </p>
    </div>
  );
};
